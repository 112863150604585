import React from 'react';
import './App.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import HeaderContainer from "./components/header/header";
import SubHeaderContainer from "./components/header/sub-header";
import { makeStyles } from '@material-ui/core/styles';
import * as colors from './assets/color';
import AboutUs from "./components/content/aboutus";
import Services from "./components/content/services";
import ContactUs from "./components/content/contact";
import Projects from "./components/content/projects";
import FooterContainer from "./components/content/footerComponent";
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles({
  headerWrapper: {
     minHeight: '50vh',
     background: `linear-gradient(45deg, ${colors.background} 30%, ${colors.backgroungFaint} 90%)`,
  }
});

function App() {
  const classes = useStyles();
  return (
    <React.Fragment>
     <CssBaseline />
     <HeaderContainer/>

     <div className={classes.headerWrapper}>
      <SubHeaderContainer/>
     </div>

       <div id="about" style={{marginTop: '2em', fontSize: '1.2em', }}>
           <AboutUs/>
       </div>

       <div id="service" style={{marginTop: '2em', fontSize: '1.2em'}}>
           <Services/>
       </div>
       <div id="contact" style={{marginTop: '2em', fontSize: '1.2em', backgroundColor: colors.backgroungFaint}}>
           <ContactUs/>
       </div>
       <div id="projects" style={{marginTop: '2em', fontSize: '1.2em'}}>
           <Projects/>
       </div>
     
    
    <footer>
      <FooterContainer/>

      <div className="scrollTop">
              <a href="#home">
                  <Fab style={{color: colors.mainSecondaryColor}} aria-label="edit">
                     <KeyboardArrowUpIcon />
                  </Fab>
              </a>
      </div>
      
    </footer>
  </React.Fragment>
  );
}

export default App;
